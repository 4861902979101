import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiSlack,
  SiJupyter,
  SiIntellijidea,
  SiLinux,
  SiEclipseide,
  SiCypress,
  SiVisualstudio,
  SiNeovim,
  SiPostman,
} from "react-icons/si";

const Toolstack = () => {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      {/* eclipse */}
      <Col xs={2} md={2} className="tech-icons">
        <SiEclipseide />
      </Col>
      {/* cypress */}
      <Col xs={2} md={2} className="tech-icons">
        <SiCypress />
      </Col>
      {/* visualstudio */}
      <Col xs={2} md={2} className="tech-icons">
        <SiVisualstudio />
      </Col>
      {/* vscode */}
      <Col xs={2} md={2} className="tech-icons">
        <SiVisualstudiocode />
      </Col>
      {/* Unix / linux */}
      <Col xs={2} md={2} className="tech-icons">
        <SiLinux />
      </Col>
      {/* neovim */}
      <Col xs={2} md={2} className="tech-icons">
        <SiNeovim />
      </Col>
      {/* slack */}
      <Col xs={2} md={2} className="tech-icons">
        <SiSlack />
      </Col>
      {/* jupyter */}
      <Col xs={2} md={2} className="tech-icons">
        <SiJupyter />
      </Col>
      {/* postman */}
      <Col xs={2} md={2} className="tech-icons">
        <SiPostman />
      </Col>
      {/* intellij */}
      <Col xs={2} md={2} className="tech-icons">
        <SiIntellijidea />
      </Col>
    </Row>
  );
};

export default Toolstack;
