import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiNodejs,
  DiReact,
  DiMsqlServer,
  DiBootstrap,
  DiSass,
  DiMongodb,
  DiMysql,
  DiSqllite,
} from "react-icons/di";
import {
  SiPostgresql,
  SiGit,
  SiDocker,
  SiGnubash,
  SiCsharp,
  SiJavascript,
  SiTypescript,
  SiNextdotjs,
  SiTailwindcss,
  SiVite,
} from "react-icons/si";

const Techstack = () => {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      {/* C# */}
      <Col xs={2} md={2} className="tech-icons">
        <SiCsharp />
      </Col>
      {/* JS */}
      <Col xs={2} md={2} className="tech-icons">
        <SiJavascript />
      </Col>
      {/* TS */}
      <Col xs={2} md={2} className="tech-icons">
        <SiTypescript />
      </Col>
      {/* nodejs */}
      <Col xs={2} md={2} className="tech-icons">
        <DiNodejs />
      </Col>
      {/* react */}
      <Col xs={2} md={2} className="tech-icons">
        <DiReact />
      </Col>
      {/* next.js */}
      <Col xs={2} md={2} className="tech-icons">
        <SiNextdotjs />
      </Col>
      {/* tailwindcss */}
      <Col xs={2} md={2} className="tech-icons">
        <SiTailwindcss />
      </Col>
      {/* vite */}
      <Col xs={2} md={2} className="tech-icons">
        <SiVite />
      </Col>
      {/* bootstrap */}
      <Col xs={2} md={2} className="tech-icons">
        <DiBootstrap />
      </Col>
      {/* sass */}
      <Col xs={2} md={2} className="tech-icons">
        <DiSass />
      </Col>

      {/* mongodb*/}
      <Col xs={2} md={2} className="tech-icons">
        <DiMongodb />
      </Col>
      {/* postgresql */}
      <Col xs={2} md={2} className="tech-icons">
        <SiPostgresql />
      </Col>
      {/* mysql */}
      <Col xs={2} md={2} className="tech-icons">
        <DiMysql />
      </Col>
      {/* msql */}
      <Col xs={2} md={2} className="tech-icons">
        <DiMsqlServer />
      </Col>
      {/* sqlite */}
      <Col xs={2} md={2} className="tech-icons">
        <DiSqllite />
      </Col>

      {/* git */}
      <Col xs={2} md={2} className="tech-icons">
        <SiGit />
      </Col>
      {/* docker */}
      <Col xs={2} md={2} className="tech-icons">
        <SiDocker />
      </Col>
      {/* bash */}
      <Col xs={2} md={2} className="tech-icons">
        <SiGnubash />
      </Col>
    </Row>
  );
};

export default Techstack;
