import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../assets/avatar.jpg";
import Tilt from "react-parallax-tilt";
import { AiFillGithub, AiOutlineMail } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

const Home2 = () => {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <div className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="blue"> INTRODUCE </span> MYSELF
            </h1>
            <div md={4} className="myAvtar">
              <Tilt>
                <img
                  src={myImg}
                  className="img-fluid framed"
                  alt="avatar"
                  height="400px"
                  width="400px"
                />
              </Tilt>
            </div>
            <div className="introduction-text">
              <p className="home-about-body">
                My name's Ali Mert. I completed my Bachelor of International
                Trade and Logistics but I have always wanted to specialize in a
                field that makes me happy, being someone who is always
                passionate about computers.
              </p>
              <p className="home-about-body">
                From a young age, I was captivated by technology. How could
                something as small as a microchip animate the digital characters
                on my screen and manage such complex tasks? It amazed me that
                this tiny piece of silicon could also bridge vast distances,
                connecting people worldwide. As a result, I started tinkering
                with gadgets and coding simple programs, driven by a desire to
                understand and harness the technology that seemed so alien at
                first glance.
              </p>
              <p className="home-about-body">
                I have been playing basketball since I was 7 years old, and I
                played professionally from the age of 12. Unfortunately, due to
                the long pandemic period, I decided not to continue my
                basketball career professionally. Currently, I continue to play
                basketball as a hobby.
              </p>
              <p className="home-about-body">
                Today, I like to introduce myself as a{" "}
                <b className="blue">Software Developer</b>
              </p>
            </div>
          </div>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>Find Me On</h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/aliwert"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/alimerterdogan/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="mailto:alimert.err@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineMail />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
export default Home2;
