import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import reactstock from "../../assets/Projects/react-stock.png";
import tsblog from "../../assets/Projects/ts-store.png";
import chospital from "../../assets/Projects/c-hospital.png";
import hangman from "../../assets/Projects/js-hangman.png";
import cpersonnel from "../../assets/Projects/c-personnel.png";
import reactmovie from "../../assets/Projects/react-movie.png";
import tsdictionary from "../../assets/Projects/ts-dictionary.png";
import lol from "../../assets/Projects/lol.png";
import cschoolnote from "../../assets/Projects/c-schoolnote.png";
import product from "../../assets/Projects/Entityproduct.png";
import mvc from "../../assets/Projects/mvc-cv-adminpanel.jpg";
import signalr from "../../assets/Projects/signalr.png";
const Projects = () => {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="blue">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col lg={4} md={6} className="project-card">
            <ProjectCard
              imgPath={signalr}
              title="Dynamic QR Code Order Management for Restaurants with AspNet Core"
              description="
              I developed an order management system for restaurants. SignalR is heavily used in the system. There is a 'Daily Deals' section where you can view discounts on the special menu of the day. You can contact us through the 'Contact Us' section. Additionally, you can read reviews from customers who have previously chosen us. In the 'Menu' section, you can select the dish you want and place your order. You can also make a reservation through the 'Reservation' section."
              ghLink="https://github.com/aliwert/QR_Order_Management_AspNet_Core"
            />
          </Col>
          <Col lg={4} md={6} className="project-card">
            <ProjectCard
              imgPath={mvc}
              title="MVC Resume and Admin Panel WebSite"
              description="
              Mvc Resume and Admin Panel Website is developed with ASP.NET MVC. It includes the contact information of the individual, their social media links, and details such as the school they graduated from on the Resume page. Additionally, there is an admin panel page where operations like About Myself, Education, Experience, Hobbies, Skills, Certificates, Contact, Social Media, Settings, and Log Out can be performed. Visitors can navigate to any desired section to learn more about the individual, but login credentials are required to access these sections."
              ghLink="https://github.com/aliwert/Mvc_Resume_Admin_Pannel"
            />
          </Col>
          <Col
            lg={4}
            md={6}
            className="project-card"
            style={{ height: "400px" }}
          >
            <ProjectCard
              imgPath={chospital}
              title="C# Hospital"
              description="The Hospital App consists of 3 different login types: patient, doctor, and secretary. In the patient section, you can create new appointments. In the doctor section, doctors can view patient complaints and hospital announcements. In the secretary section, secretaries can create new appointments, add announcements, and perform a variety of administrative tasks. The data is stored in a Microsoft SQL database."
              ghLink="https://github.com/aliwert/Hospital_Project"
            />
          </Col>
          <Col lg={4} md={6} className="project-card">
            <ProjectCard
              imgPath={cpersonnel}
              title="C# Personnel Registration"
              description="The personnel registration project is designed primarily for institutions like factories. You can manage information related to personnel through this application. When you hire new personnel, you can enter their data into the system. The data is stored in a Microsoft SQL database."
              ghLink="https://github.com/aliwert/Personnel_Registration_App"
            />
          </Col>
          <Col lg={4} md={6} className="project-card">
            <ProjectCard
              imgPath={cschoolnote}
              title="C# School Note"
              description="The School Note project is an application that shows a student's grades based on their entered student number. For teachers, it provides a platform to organize student clubs, add and manage students, input exam scores, and calculate grades. The app also indicates whether a student has successfully completed a class based on their average grades."
              ghLink="https://github.com/aliwert/School_Note_Project"
            />
          </Col>
          <Col lg={4} md={6} className="project-card">
            <ProjectCard
              imgPath={product}
              title="C# Product Management"
              description="The Product Management application is a project designed for stores. This app allows you to register any product into the system, specifying its type, price, and brand. With this application, you can also manage and conduct inventory counts as needed, making it an essential tool for efficient store operations."
              ghLink="https://github.com/aliwert/Entity_Product_Project"
            />
          </Col>
          <Col lg={4} md={6} className="project-card">
            <ProjectCard
              imgPath={reactstock}
              title="Stock App"
              description="The Stock App is a robust web application designed to manage and visualize a company's stock information. This application provides administrators with an intuitive interface to update, add, edit, and delete products in the company's stock. Additionally, it offers advanced visualization of stock movements through graphical representations, making inventory management more efficient and insightful."
              demoLink="https://react-stock-bvsuo6l95-aliwerts-projects.vercel.app/"
              ghLink="https://github.com/aliwert/react-stock-app"
            />
          </Col>
          <Col lg={4} md={6} className="project-card">
            <ProjectCard
              imgPath={tsblog}
              title="TypeScript Blog"
              description="The Store App is an application where you can view products, check their stock information, view their prices, and add them to your favorites. The products you add to your favorites are accessible in the favorites section."
              ghLink="https://github.com/aliwert/ts-storeapp"
            />
          </Col>

          <Col lg={4} md={6} className="project-card">
            <ProjectCard
              imgPath={hangman}
              title="Hangman Game"
              description="The Hangman game consists of 3 different categories, offering a variety of words to guess from. You have 7 attempts to guess the correct word. If you fail to guess the word within these 7 attempts, the game ends and restarts, allowing you to choose a category and start a new round."
              ghLink="https://github.com/aliwert/hangman-game"
            />
          </Col>

          <Col lg={4} md={6} className="project-card">
            <ProjectCard
              imgPath={reactmovie}
              title="Movie App"
              description="The movie app, you need to register first to perform searches. After registering, you can search for any movie you want. When you click on a movie, the app provides information about the movie and shows its trailer. There are options to continue with Google and GitHub accounts."
              ghLink="https://github.com/aliwert/react-movie-app"
            />
          </Col>
          <Col lg={4} md={6} className="project-card">
            <ProjectCard
              imgPath={tsdictionary}
              title="TypeScript Dictionary"
              description="A dictionary app is an application designed to provide users with comprehensive information about words. When you type in a word, the app displays its meaning, pronunciation, usage examples, and sometimes word origin and translation. Some advanced dictionary apps may also offer features like the ability to save favorite words or create personalized word lists. This makes it a valuable tool for language learners, writers, and anyone looking to expand their vocabulary."
              ghLink="https://github.com/aliwert/ts-dictionary-app"
            />
          </Col>
          <Col lg={4} md={6} className="project-card">
            <ProjectCard
              imgPath={lol}
              title="League of Legends Characters"
              description="
              A League of Legends app is an application that displays all the characters' stats, including their abilities, win rates, pick rates, and ban rates. You can access any champion by typing their name into the search bar. This app is an invaluable resource for players looking to gain insights into different champions and improve their gameplay."
              ghLink="https://github.com/aliwert/react-lol-characters"
              demoLink="https://react-lol-characters.vercel.app/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Projects;
